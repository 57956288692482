import React, { Component } from 'react'
import Select, { createFilter } from "react-select";
import { sweetalert } from "../../App";
import { buildRow, getLabel, getLabelsForMultiSelect, hideModal, parseDate, formatPhone, getReactSelectAriaLabel, getReactSelectAriaLabelMultiselect } from "../../util/FormatUtil";
import SystemAPI from "../../network/SystemAPI";
import PhoneInput from 'react-phone-number-input';
import { AiOutlineDown, AiOutlineUp, AiOutlineCloudDownload } from "react-icons/ai";
import Validator, { ValidationEntry } from "../../validation/Validator";
import { Validators } from "../../validation/Validators";
import ExemptionAPI from '../../network/ExemptionAPI';
import Overlay from "../Overlay";
import InBetweenOverlay from "../InBetweenOverlay";
import SamplesAPI from '../../network/SamplesAPI';
import AutoComplete from '../admin/AutoComplete';
import moment from "moment-timezone";
import { get } from 'jquery';

interface ExemptionModalState {
  selectedExemption
  customFieldData?
  ethnicities?
  genders?
  races?
  immunizations?
  exemptionArrowDown?
  childArrowDown?
  parentArrowDown?
  schoolArrowDown?
  fileUploadArrowDown?
  gradeOptions?
  showLoading?
  showInBetween?
  selectedFiles;
  relationships?
  exemptionTypes?
  districts?
  schools?
  schoolOptions?
  childCareFacilities?
  physicianEmailBeforeEdit?
  exemptionReasons?
  immunizationOptions?
}
interface ExemptionModalProps {
  selectedExemption
  immunizations
  countries
  states
  genders
  races
  onSubmit
  isAdmin?
  ethnicities
  onClose?
}


export default class ExemptionRecordModal extends Component<ExemptionModalProps, ExemptionModalState> {
  public static ID = "ExemptionRecordModal";

  constructor(props) {
    super(props);

    this.state = {
      selectedExemption: {},
      selectedFiles: [],
      physicianEmailBeforeEdit: null,
      gradeOptions: [
        { label: "Headstart", value: "Headstart" },
        { label: "Pre-K", value: "Pre-K" },
        { label: "Kindergarten", value: "Kindergarten" },
        { label: "1st", value: "1st" },
        { label: "2nd", value: "2nd" },
        { label: "3rd", value: "3rd" },
        { label: "4th", value: "4th" },
        { label: "5th", value: "5th" },
        { label: "6th", value: "6th" },
        { label: "7th", value: "7th" },
        { label: "8th", value: "8th" },
        { label: "9th", value: "9th" },
        { label: "10th", value: "10th" },
        { label: "11th", value: "11th" },
        { label: "12th", value: "12th" }
      ],
      showLoading: false
    };

  }

  componentDidMount(): void {
    $(`#${ExemptionRecordModal.ID}`).on('shown.bs.modal', function () {
      $(document).off('focusin.modal');
    });
    SystemAPI.getAllEthnicities().then(data => {
      this.setState({ ethnicities: data })
    })
    SystemAPI.getAllGenders().then(data => {
      this.setState({ genders: data })
    })
    SystemAPI.getAllRaces().then(data => {
      this.setState({ races: data })
    })
    SystemAPI.getAllImmunizations().then(data => {
      let arrayWithAllSelection = [{ label: "All", value: 0 }].concat(data as { label: string; value: number; }[])
      this.setState({ immunizations: data, immunizationOptions: arrayWithAllSelection })
    })
    SystemAPI.getAllRelationships().then(data => {
      this.setState({ relationships: data })
    })
    SystemAPI.getAllExemptionTypes().then(data => {
      this.setState({ exemptionTypes: data })
    })
    SystemAPI.getAllSchoolDistricts().then(data => {
      this.setState({ districts: data })
    })
    SystemAPI.getAllSchools().then(data => {
      this.setState({ schools: data.schools })
    })
    SystemAPI.getAllChildCareFacilities().then(data => {
      //@ts-ignore
      this.setState({ childCareFacilities: data.facilities.map((facility) => { return { label: facility.ChildCareSiteName, value: facility.ID } }) })
    })
    SystemAPI.getAllPersonalExemptionReasons().then(data => {
      this.setState({ exemptionReasons: data })
    })
  }


  componentWillReceiveProps(nextProps: Readonly<ExemptionModalProps>, nextContext: any): void {

    this.setState({
      selectedExemption: {
        ...this.props.selectedExemption,
        fileNames: this.props.selectedExemption && this.props.selectedExemption.FileUploadPaths ? this.getFileNames(this.props.selectedExemption.FileUploadPaths) : [],
        ChildRaceID: this.props.selectedExemption ? getLabelsForMultiSelect(JSON.parse(this.props.selectedExemption?.ChildRaceID), this.state.races) : null,
        DenialReason: this.props.selectedExemption ? getLabel(this.props.selectedExemption.DenialReason, [{ label: "Fraudulent Request", value: "Fraudulent Request" }, { label: "Duplicate Exemption", value: "Duplicate Exemption" }, { label: "Other", value: "Other" }]) : null,
        ExemptImmunizations: this.props.selectedExemption ? getLabelsForMultiSelect(JSON.parse(this.props.selectedExemption?.ExemptImmunizations), this.state.immunizations) : null,
        Status: this.props.selectedExemption ? getLabel(this.props.selectedExemption.Status, [{ label: "Approved", value: 1 }, { label: "Denied", value: 0 }, { label: "Pending", value: null }]) : null,
        PhysicianStatus: this.props.selectedExemption ? getLabel(this.props.selectedExemption.PhysicianStatus, [{ label: "Approved", value: 1 }, { label: "Denied", value: 0 }, { label: "Pending", value: null }]) : null,
        ContraIndicatedImmunizations: this.props.selectedExemption?.ContraIndicatedImmunizations && JSON.parse(this.props.selectedExemption?.ContraIndicatedImmunizations).length > 0 ? getLabelsForMultiSelect(JSON.parse(this.props.selectedExemption?.ContraIndicatedImmunizations), this.state.immunizations) : null,
        EducationSite: this.props.selectedExemption && this.props.selectedExemption.EducationSite ? JSON.parse(this.props.selectedExemption.EducationSite) : null
      }, physicianEmailBeforeEdit: this.props.selectedExemption?.PhysicianEmail
    })
  }

  getExemptionType() {
    if (this.state.selectedExemption?.ExemptionType === 1) {
      return { label: "Medical Exemption", value: 1 }
    } else if (this.state.selectedExemption?.ExemptionType === 2) {
      return { label: "Religious Objection", value: 2 }
    } else if (this.state.selectedExemption?.ExemptionType === 3) {
      return { label: "Personal Objection", value: 3 }
    } else {
      return null
    }
  }

  getFileNames(urlArray) {
    let parsedArray = Array.from(JSON.parse(urlArray));

    let namesOnly = [];
    parsedArray.map(f => {
      //@ts-ignore
      let temp = f.split('/').pop();
      namesOnly.push(temp)
    })
    return namesOnly

  }

  // handleSendPhysicianEmail() {
  //   this.setState({ showLoading: true }, () => {
  //     ExemptionAPI.sendPhysicianEmail(this.state.selectedExemption).then(async res => {
  //       if (res.success) {
  //         this.setState({ showLoading: false })
  //         await sweetalert.fire({
  //           icon: "success",
  //           title: "",
  //           // @ts-ignore
  //           text: "Email has been sent to the physician.",
  //         })
  //       } else {
  //         this.setState({ showLoading: false })
  //         await sweetalert.fire({
  //           icon: "error",
  //           title: "Server Error",
  //           // @ts-ignore
  //           text: "Please try again!",
  //         });
  //       }
  //     })
  //   })

  // }

  getRacesList() {
    if (this.state.selectedExemption?.ChildRaceID?.length < 2) {
      return getLabel(parseInt(this.state.selectedExemption?.ChildRaceID), this.state.races).label
    } else {
      let raceArray = this.state.selectedExemption?.ChildRaceID?.split(',')
      let raceStringArray = [];
      for (let val of raceArray) {
        raceStringArray.push(getLabel(parseInt(val), this.state.races).label);
      }
      let raceString = raceStringArray.join(", ");
      return raceString;
    }
  }

  getContraindicatedImmunizationsList() {
    if (this.state.selectedExemption?.ContraIndicatedImmunizations?.length < 2) {
      return [getLabel(parseInt(this.state.selectedExemption?.ContraIndicatedImmunizations), this.state.immunizations)]
    } else {
      let immunizationStringArray = [];
      for (let val of this.state.selectedExemption.ContraIndicatedImmunizations) {
        immunizationStringArray.push(getLabel(parseInt(val), this.state.immunizations));
      }
      return immunizationStringArray;
    }
  }

  getExemptImmunizationsList() {
    if (this.state.selectedExemption?.ExemptImmunizations?.length < 2) {
      return [getLabel(parseInt(this.state.selectedExemption?.ExemptImmunizations), this.state.immunizations)]
    } else {
      let immunizationStringArray = [];
      for (let val of this.state.selectedExemption.ExemptImmunizations) {
        immunizationStringArray.push(getLabel(parseInt(val), this.state.immunizations));
      }
      return immunizationStringArray;

    }
  }

  validateForm(exemptionInfoCopy) {
    if (!exemptionInfoCopy.ExemptionType) {
      return { success: false, error: "You must select an Exemption Type" }
    }
    let exemptionFormValidation = {
      PersonalExemptionReason: exemptionInfoCopy.PersonalExemptionReason,
      PhysicianFirstName: exemptionInfoCopy.PhysicianFirstName,
      PhysicianLastName: exemptionInfoCopy.PhysicianLastName,
      PhysicianAddress: exemptionInfoCopy.PhysicianAddress,
      PhysicianCity: exemptionInfoCopy.PhysicianCity,
      PhysicianState: exemptionInfoCopy.PhysicianState,
      PhysicianZip: exemptionInfoCopy.PhysicianZip,
      PhysicianPhone: exemptionInfoCopy.PhysicianPhone,
      PhysicianEmail: exemptionInfoCopy.PhysicianEmail,
      ObjectionSummary: exemptionInfoCopy.ObjectionSummary,
      ExemptImmunizations: exemptionInfoCopy.ExemptImmunizations,
      ChildFirstName: exemptionInfoCopy.ChildFirstName,
      ChildMiddleInitial: exemptionInfoCopy.ChildMiddleInitial,
      ChildLastName: exemptionInfoCopy.ChildLastName,
      ChildBirthCountry: exemptionInfoCopy.ChildBirthCountry,
      ChildBirthState: exemptionInfoCopy.ChildBirthState,
      ChildDOB: parseDate(new Date(exemptionInfoCopy.ChildDOB)),
      ChildEthnicityID: exemptionInfoCopy.ChildEthnicityID,
      ChildGenderID: exemptionInfoCopy.ChildGenderID,
      ChildRaceID: exemptionInfoCopy.ChildRaceID,
      MotherMaidenName: exemptionInfoCopy.MotherMaidenName,
      ParentAddress: exemptionInfoCopy.ParentAddress,
      ParentCity: exemptionInfoCopy.ParentCity,
      ParentCounty: exemptionInfoCopy.ParentCounty,
      ParentEmail: exemptionInfoCopy.ParentEmail,
      ParentFirstName: exemptionInfoCopy.ParentFirstName,
      ParentLastName: exemptionInfoCopy.ParentLastName,
      ParentMiddleInitial: exemptionInfoCopy.ParentMiddleInitial,
      ParentPhone: exemptionInfoCopy.ParentPhone,
      ParentState: exemptionInfoCopy.ParentState,
      ParentZip: exemptionInfoCopy.ParentZip,
    };


    let validator = new Validator<any>()
      .withSimpleValidation("ChildFirstName", Validators.requireNonNullValidator("Child First Name"))
      .withSimpleValidation("ChildLastName", Validators.requireNonNullValidator("Child Last Name"))
      .withSimpleValidation("ChildBirthCountry", Validators.requireNonNullValidator("Child Birth Country"))
      .withValidation("ChildDOB", Validators.requireSchoolAgeDOB("Child Date of birth"))
      .withSimpleValidation("ChildEthnicityID", Validators.requireNonNullValidator("Child Ethnicity"))
      .withSimpleValidation("ChildGenderID", Validators.requireNonNullValidator("Child Gender"))
      .withSimpleValidation("ChildRaceID", Validators.requireNonNullValidator("Child Race"))
      .withSimpleValidation("MotherMaidenName", Validators.requireNonNullValidator("Mother Maiden Name"))
      .withSimpleValidation("ParentAddress", Validators.requireNonNullValidator("Parent Address"))
      .withSimpleValidation("ParentCity", Validators.requireNonNullValidator("Parent City"))
      .withSimpleValidation("ParentState", Validators.requireNonNullValidator("Parent State"))
      .withSimpleValidation("ParentZip", Validators.requireNonNullValidator("Parent Zip"))
      .withSimpleValidation("ParentCounty", Validators.requireNonNullValidator("Parent County"))
      .withComposedValidation("ParentEmail", new ValidationEntry(Validators.requireNotBlankValidator("Parent Email")), new ValidationEntry(Validators.requireValidEmail("Parent Email")), new ValidationEntry(Validators.requireLength(200, "Parent Email")))
      .withSimpleValidation("ParentFirstName", Validators.requireNonNullValidator("Parent First Name"))
      .withSimpleValidation("ParentLastName", Validators.requireNonNullValidator("Parent Last Name"))
      .withComposedValidation("ParentPhone", new ValidationEntry(Validators.requireNonNullValidator("Parent Phone")), new ValidationEntry(Validators.requirePhone("Parent Phone")))

    if (exemptionFormValidation.ChildBirthCountry === "United States") {
      validator = validator.withSimpleValidation("ChildBirthState", Validators.requireNonNullValidator("Birth State"))
    }

    if (exemptionInfoCopy.ExemptionType === 5) {
      validator = validator
        .withSimpleValidation("PhysicianFirstName", Validators.requireNonNullValidator("Physician First Name"))
        .withSimpleValidation("PhysicianLastName", Validators.requireNonNullValidator("Physician Last Name"))
        .withSimpleValidation("PhysicianAddress", Validators.requireNonNullValidator("Physician Address"))
        .withSimpleValidation("PhysicianCity", Validators.requireNonNullValidator("Physician City"))
        .withSimpleValidation("PhysicianState", Validators.requireNonNullValidator("Physician State"))
        .withSimpleValidation("PhysicianZip", Validators.requireNonNullValidator("Physician Zipcode"))
        .withComposedValidation("PhysicianPhone", new ValidationEntry(Validators.requireNonNullValidator("Physician Phone")), new ValidationEntry(Validators.requirePhone("Physician Phone")))
        .withSimpleValidation("ExemptImmunizations", Validators.requireNonNullValidator("Immunizations for Exemption"))
    } else if (exemptionInfoCopy.ExemptionType === 2) {
      validator = validator
        .withSimpleValidation("ExemptImmunizations", Validators.requireNonNullValidator("Immunizations for Exemption"))
    } else if (exemptionInfoCopy.ExemptionType === 1) {
      validator = validator
        // .withSimpleValidation("ObjectionSummary", Validators.requireNonNullValidator("Objection Summary"))
        .withSimpleValidation("PersonalExemptionReason", Validators.requireNonNullValidator("Exemption Reason"))
        .withSimpleValidation("ExemptImmunizations", Validators.requireNonNullValidator("Immunizations for Exemption"))
    }



    let validationResponse = validator.validate(exemptionFormValidation);

    if (!validationResponse.success) {
      return { success: false, error: validationResponse.error }
    } else {
      return { success: true }
    }
  }

  handleResult(res, sentPhysicianEmail?) {
    if (res.success) {
      this.setState({ showLoading: false }, () => {
        sweetalert.fire({
          icon: "success",
          title: "",
          // @ts-ignore
          text: `Exemption has been updated. ${this.state.selectedExemption.Status.value ? 'HL7 message has been sent.' : ''}`,
        })
          .then(async (result) => {
            // if (this.state.selectedExemption.PhysicianEmail !== this.state.physicianEmailBeforeEdit) {
            //   await sweetalert.fire({
            //     icon: "warning",
            //     title: "",
            //     confirmButtonText: "Yes",
            //     showDenyButton: true,
            //     denyButtonText: "No",
            //     // @ts-ignore
            //     html: `The Physician Email has changed, would you like to resend the Physician Review email?`,
            //   }).then(async (result) => {
            //     if (result.isConfirmed) {
            //       res = await this.handleSendPhysicianEmail()
            //       if (res.success) {
            //         this.setState({ showLoading: false })
            //         await sweetalert.fire({
            //           icon: "success",
            //           title: "",
            //           // @ts-ignore
            //           text: "Email has been sent to the physician.",
            //         })
            //       } else {
            //         this.setState({ showLoading: false })
            //         await sweetalert.fire({
            //           icon: "error",
            //           title: "Server Error",
            //           // @ts-ignore
            //           text: "Please try again!",
            //         });
            //       }
            //     }
            //   })
            // }
            if (result.isConfirmed) {
              this.props.onSubmit()
            }
          });
      })
    } else {
      this.setState({ showLoading: false }, () => {
        return sweetalert.fire({
          icon: "error",
          title: "Server Error",
          // @ts-ignore
          text: "Please try again!",
        });
      })
    }
  }

  handleAddSchool() {
    this.setState({
      selectedExemption: {
        ...this.state.selectedExemption,
        EducationSite: [
          ...this.state.selectedExemption.EducationSite,
          { schoolOrChildCare: null, SchoolDistrict: null, School: null, ChildCareSite: null, SchoolGrade: null, SchoolPhone: null }
        ]
      }
    });
  }

  handleRemoveSchool(index) {
    let schoolArray = this.state.selectedExemption.EducationSite;
    let editedSchoolArray = schoolArray.splice(index, 1);
    this.setState({
      selectedExemption: {
        ...this.state.selectedExemption,
        EducationSite: schoolArray
      }
    })
  }

  handleUpdateSchool(index, key, value) {
    let schoolArray = this.state.selectedExemption.EducationSite
    schoolArray[index][key] = value;

    if (!this.state.selectedExemption.EducationSite[index].SchoolDistrict && key === "School" && (value !== -1 && this.state.selectedExemption.EducationSite[index].School !== -1)) {
      schoolArray[index].SchoolDistrict = this.state.schools.find((s) => s.ID === value).SchoolDistrictID
    }
    if (key === "SchoolDistrict" && (value !== -1 && this.state.selectedExemption.EducationSite[index].School !== -1)) {
      schoolArray[index].School = null
    }
    this.setState((prevState) => ({
      selectedExemption: {
        ...this.state.selectedExemption,
        EducationSite: schoolArray,
      }
    }))
  }

  validateSchool(schoolInfo) {
    let patientFormValidation = {
      ChildCareSite: schoolInfo?.ChildCareSite,
      School: schoolInfo?.School,
      District: schoolInfo?.SchoolDistrict,
      Grade: schoolInfo?.SchoolGrade,
      Phone: schoolInfo?.SchoolPhone,
      OtherSchool: schoolInfo?.OtherSchool,
      OtherSchoolDistrict: schoolInfo?.OtherSchoolDistrict,
      OtherChildCareSite: schoolInfo?.OtherChildCareSite
    };

    let validator = new Validator<any>()
    if (schoolInfo.schoolOrChildCare === 0) {
      validator = validator.withSimpleValidation("Grade", Validators.requireNonNullValidator())
        .withSimpleValidation("School", Validators.requireNonNullValidator())
        .withSimpleValidation("District", Validators.requireNonNullValidator())
      if (schoolInfo.School == -1) {
        validator = validator.withSimpleValidation("OtherSchool", Validators.requireNonNullValidator("Other Name of School"))
      }
      if (schoolInfo.SchoolDistrict == -1) {
        validator = validator.withSimpleValidation("OtherSchoolDistrict", Validators.requireNonNullValidator("Other School District"))
      }
    } else if (schoolInfo.schoolOrChildCare === 1) {
      validator = validator.withSimpleValidation("ChildCareSite", Validators.requireNonNullValidator("Child Care Site"))
      if (schoolInfo.ChildCareSite == -1) {
        validator = validator.withSimpleValidation("OtherChildCareSite", Validators.requireNonNullValidator("Other Child Care Site"))
      }
    }

    let validationResponse = validator.validate(patientFormValidation);
    if (!validationResponse.success) {
      return { success: false, error: validationResponse.error }
    } else {
      return { success: true }
    }
  }

  async submitForm() {

    let sentPhysicianEmail;

    let exemptImmunizationsArray = this.state.selectedExemption.ExemptImmunizations?.map(obj => obj.value)
    let exemptImmunizationsString = exemptImmunizationsArray ? exemptImmunizationsArray.join(',') : null;
    let contraImmunizationsArray = this.state.selectedExemption.ContraIndicatedImmunizations ? this.state.selectedExemption.ContraIndicatedImmunizations.map(obj => obj.value) : null;
    let contraImmunizationsString = contraImmunizationsArray ? contraImmunizationsArray.join(',') : null;
    let childRaceArray = this.state.selectedExemption.ChildRaceID?.map(obj => obj?.value)

    let exemptionInfoCopy = {
      ...this.state.selectedExemption,
      ChildRaceID: JSON.stringify(childRaceArray),
      ExemptImmunizations: JSON.stringify(exemptImmunizationsArray),
      ContraIndicatedImmunizations: contraImmunizationsArray ? JSON.stringify(contraImmunizationsArray) : null,
      statusChanged: this.props.selectedExemption.Status != this.state.selectedExemption.Status.value,
    }
    delete exemptionInfoCopy.isApproved;
    delete exemptionInfoCopy.CreatedBy;
    delete exemptionInfoCopy.CreatedDate;
    delete exemptionInfoCopy.AcknowledgedAgreement;
    delete exemptionInfoCopy.ApprovalDate;


    for (let i = 0; i < this.state.selectedExemption.EducationSite.length; i++) {
      let schools = JSON.parse(JSON.stringify(this.state.selectedExemption.EducationSite))
      let school = schools[i]

      let res = this.validateSchool(school)
      if (!res.success) {
        this.setState({ showLoading: false })
        return sweetalert.fire({
          icon: "error",
          title: "",
          text: res.error,
        });
      }

      school.SchoolGrade = school.SchoolGrade ? school.SchoolGrade.trim() : null
      school.SchoolPhone = school.SchoolPhone ? school.SchoolPhone.trim() : null

      schools[i] = school
      this.setState({
        selectedExemption: {
          ...this.state.selectedExemption,
          EducationSite: schools
        }
      })
    }

    let res = await this.validateForm(exemptionInfoCopy)



    // @ts-ignore
    if (!res.success) {
      this.setState({ showLoading: false })
      return sweetalert.fire({
        icon: "error",
        title: "",
        // @ts-ignore
        text: res.error,
      });
    } else {
      this.setState({ showLoading: true }, async () => {
        let res;
        if (this.props.selectedExemption.Status != this.state.selectedExemption.Status.value && (this.state.selectedExemption.Status.value === 1 || this.state.selectedExemption.Status.value === 0)) {
          res = await sweetalert.fire({
            icon: "warning",
            title: "",
            confirmButtonText: "Yes",
            showDenyButton: true,
            denyButtonText: "No",
            // @ts-ignore
            html: `<p>Are you sure you want to <strong>${exemptionInfoCopy.Status.value === 1 ? "Approve" : "Deny"}</strong> this exemption?</p>`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let res = await ExemptionAPI.editExemption(exemptionInfoCopy);
              this.handleResult(res)
            } else {
              this.setState({ showLoading: false })
              return
            }
          });
        } else {
          let res = await ExemptionAPI.editExemption(exemptionInfoCopy);
          this.handleResult(res, sentPhysicianEmail)
        }
      })
    }
  }

  downloadFile = async (name) => {
    this.setState({ showInBetween: true })
    let file = await SamplesAPI.downloadFilesFromCloud({ ReqNum: this.state.selectedExemption?.ReqNum, fileName: name })
    try {
      if (typeof file === "object") {
        sweetalert.fire({
          icon: "error",
          title: "",
          text: "File not found",
        });
        this.setState({ showInBetween: false });
        return;
      } else {
        const link = document.createElement("a");
        link.href = file;
        link.download = `${name}`;
        link.click();
        this.setState({ showInBetween: false })
      }
    } catch (error) {
      console.error('error downloading file', error)
      this.setState({ showInBetween: false })
      return sweetalert.fire({ icon: 'error', title: '', text: 'Could not download file. Please try again.' })
    }
  }

  removeFile = (fileName) => {

    sweetalert.fire({
      showCancelButton: false,
      showConfirmButton: true,
      showDenyButton: true,
      denyButtonColor: 'green',
      denyButtonText: 'Cancel',
      confirmButtonText: 'Delete',
      confirmButtonColor: '#C31230',
      title: '',
      html: `<h5>Are you sure you want to delete <b>${fileName}</b>?</h5>`,
      icon: 'warning'
    })
      .then((result) => {
        if (result.isDenied) {
          return
        }
        else {
          let fileNamesCopy = this.state.selectedExemption && this.state.selectedExemption.fileNames && this.state.selectedExemption.fileNames.length > 0 ? JSON.parse(JSON.stringify(this.state.selectedExemption.fileNames)) : null;

          let indexToRemove = fileNamesCopy.indexOf(fileName);
          if (indexToRemove >= 0) {
            fileNamesCopy.splice(indexToRemove, 1)
          }

          let selectedFilesCopy = this.state.selectedFiles && this.state.selectedFiles.length > 0 ? this.state.selectedFiles : [];

          if (selectedFilesCopy) {
            let indexToRemoveFile = selectedFilesCopy.findIndex(file => file.name.replace(/\s+/g, '') === fileName);

            if (indexToRemoveFile >= 0) {
              selectedFilesCopy.splice(indexToRemoveFile, 1)
            }

          }

          this.setState((prevState) => ({
            selectedFiles: selectedFilesCopy,
            selectedExemption: {
              ...prevState.selectedExemption,
              fileNames: fileNamesCopy
            }
          }))
        }
      })


  }

  onDrop = (files) => {
    let combinedFileNames = [];
    combinedFileNames = this.state.selectedExemption?.fileNames ? combinedFileNames.concat(this.state.selectedExemption.fileNames) : [];
    let newFileName = files[0].name.replace(/\s+/g, '');
    combinedFileNames.push(newFileName)

    //check for duplicate
    const seen = new Set();
    const duplicates = combinedFileNames.filter(item => seen.size === seen.add(item).size);
    if (duplicates && duplicates.length > 0) {
      return sweetalert.fire('', `<b>${duplicates[0]}</b> already selected`, 'error')
    }

    //update state
    this.setState((prevState) => ({
      //add file to selectedFiles
      selectedFiles: [...prevState.selectedFiles, files[0]],
      //add name to this.state.selectedSample.fileNames
      selectedExemption: {
        ...prevState.selectedExemption,
        fileNames: [...prevState.selectedExemption.fileNames, newFileName]
      }
    }))
  };

  handleRaceChange(e, st?) {
    let raceIDsArray = JSON.parse(JSON.stringify(this.state.selectedExemption.ChildRaceID));
    // let idToReplace = raceIDsArray.indexOf( st.value );
    // if ( idToReplace !== -1 ) { raceIDsArray.splice( idToReplace, 1, e.value ); }


    let duplicates = raceIDsArray.filter((currentValue, currentIndex) => raceIDsArray.indexOf(currentValue) !== currentIndex);
    if (duplicates.length > 0) {
      return sweetalert.fire({ icon: "error", title: "", text: "Test must not be duplicated.", });
    }
    this.setState(
      (prevState) => ({
        selectedExemption: {
          ...prevState.selectedExemption,
          ChildRaceID: raceIDsArray,
        },
      })
    );
  }


  changeStatus(status) {

    sweetalert.fire({
      icon: "question",
      title: "",
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showDenyButton: true,
      text: `Are you sure you want to ${status ? "Approve" : "Deny"} this Exemption?`,
    })
      .then((result) => {
        if (result.isConfirmed) {
          this.setState({ showLoading: true }, async () => {
            let res = await ExemptionAPI.updateExemptionStatus(this.state.selectedExemption, status);

            if (res.success) {
              this.setState({ showLoading: false }, () => {
                sweetalert.fire({
                  icon: "success",
                  title: "",
                  // @ts-ignore
                  text: "The exemption status has been updated.",
                })
                  .then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload()
                    }
                  });
              })
            } else {
              this.setState({ showLoading: false }, () => {
                return sweetalert.fire({
                  icon: "error",
                  title: "Server Error",
                  // @ts-ignore
                  text: "Please try again!",
                });
              })
            }
          })
        }
      });



  }


  render() {

    let countriesCopy = this.props.countries && this.props.countries.length > 0 ? JSON.parse(JSON.stringify(this.props.countries)) : [];
    let indexOfUS = countriesCopy.findIndex(obj => obj.value === 'United States');
    let [removedObj] = countriesCopy.splice(indexOfUS, 1);
    countriesCopy.unshift(removedObj)

    return (
      <>
        <div className="modal fade form_modal" id={ExemptionRecordModal.ID} tabIndex={-1} role="dialog"
          aria-labelledby="result_modal_label"
          aria-hidden="true">
          <Overlay show_loading={this.state.showLoading} zIndex={10005} />
          <InBetweenOverlay showInBetween={this.state.showInBetween} />
          <div className="modal-dialog modal-lg modal-xl col-8 px-0" role="document">
            <div className="modal-content">
              <div className="container-fluid" >
                <div className="modal-header">
                  <h5 className="modal-title" id="result_modal_label">Exemption Record</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.onClose()}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body p-0">
                  <section className='my-2 mx-auto col-11'>
                    {buildRow("Status",
                      <Select
                        isSearchable={true}
                        placeholder={<div className="accessibilityText">Please Select...</div>}
                        noOptionsMessage={() => "No option"}
                        value={this.state.selectedExemption?.Status}
                        onChange={(e) => this.setState((prevState) => ({
                          selectedExemption: {
                            ...prevState.selectedExemption,
                            Status: e
                          }
                        }))}
                        className={"state_select col-12 mx-auto p-0"}
                        aria-label={getReactSelectAriaLabel("Status", this.state.selectedExemption?.Status)}
                        options={[
                          { label: "Approved", value: 1 },
                          { label: "Denied", value: 0 },
                          { label: "Pending", value: null }
                        ]}
                        readOnly={!this.props.isAdmin}
                        isDisabled={!this.props.isAdmin}
                      />
                    )}
                    {this.state.selectedExemption?.Status?.value === 0 ? buildRow("Denial Reason",
                      <Select
                        isSearchable={true}
                        placeholder={<div className="accessibilityText">Please Select...</div>}
                        noOptionsMessage={() => "No option"}
                        value={this.state.selectedExemption?.DenialReason}
                        onChange={(e) => this.setState((prevState) => ({
                          selectedExemption: {
                            ...prevState.selectedExemption,
                            DenialReason: e
                          }
                        }))}
                        className={"state_select col-12 mx-auto p-0"}
                        aria-label={getReactSelectAriaLabel("Denial Reason", this.state.selectedExemption?.DenialReason)}
                        options={[
                          { label: "Fraudulent Request", value: "Fraudulent Request" },
                          { label: "Duplicate Exemption", value: "Duplicate Exemption" },
                          { label: "Other", value: "Other" }
                        ]}
                        readOnly={!this.props.isAdmin}
                        isDisabled={!this.props.isAdmin}
                      />
                    ) : <></>}
                    {this.state.selectedExemption?.DenialReason?.value === "Other" ? buildRow("Other Denial Reason",
                      <input
                        id="OtherDenialReason"
                        className={"form-control col-12 mx-auto"}
                        autoComplete={"off"}
                        type={"search"}
                        name={"Other Denial Reason"}
                        aria-label="Other Denial Reason"
                        value={this.state.selectedExemption?.OtherDenialReason}
                        onChange={(e) => {
                          this.setState((prevState) => ({
                            selectedExemption: {
                              ...prevState.selectedExemption,
                              OtherDenialReason: e.target.value
                            }
                          }))
                        }}
                      />
                    ) : <></>}
                    {(this.state.selectedExemption?.Status?.value === 1 || this.state.selectedExemption?.Status?.value === 0) ?
                      buildRow(`Date of ${this.state.selectedExemption?.Status?.value ? "Approval" : "Denial"}`,
                        <input
                          readOnly={true}
                          id="StatusDate"
                          className={"form-control col-12 mx-auto"}
                          autoComplete={"off"}
                          type={"search"}
                          name={"Date of Status"}
                          aria-label="Date of Status"
                          value={this.state.selectedExemption?.Status.value === 1 ? (this.state.selectedExemption?.ApprovalDate ? moment(this.state.selectedExemption?.ApprovalDate).format("MM-DD-YYYY hh:mm a") : "") : (this.state.selectedExemption?.DenialDate ? moment(this.state.selectedExemption?.DenialDate).format("MM-DD-YYYY hh:mm a") : "")}
                        />
                      ) : <></>}

                    {buildRow("Confirmation Code",
                      <input className={"form-control col-12 mx-auto"}
                        maxLength={50}
                        readOnly={true}
                        autoComplete={"off"}
                        type={"search"}
                        name={"Result"}
                        aria-label='Confirmation Code'
                        value={this.state.selectedExemption?.ReqNum}
                      />
                    )}
                    {buildRow("Date/Time of Submission",
                      <input className={"form-control col-12 mx-auto"}
                        maxLength={50}
                        readOnly={true}
                        autoComplete={"off"}
                        type={"search"}
                        name={"Result"}
                        aria-label='Submission Date'
                        value={this.state.selectedExemption?.CreatedDate ? moment(this.state.selectedExemption?.CreatedDate).format("MM-DD-YYYY hh:mm a") : ""}
                      />
                    )}
                  </section>
                  <div className="card">
                    <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#exemptionXLCollapse" onClick={() => this.setState({ exemptionArrowDown: !this.state.exemptionArrowDown, })} aria-expanded="false" aria-controls="exemptionXLCollapse" >
                      <div className="row justify-content-between px-3">
                        <div className="">
                          <h4>Exemption</h4>
                        </div>
                        <div className="col-2">
                          {this.state.exemptionArrowDown ? (
                            <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#exemptionXLCollapse" role="button" aria-expanded="false" aria-controls="exemptionXLCollapse" />
                          ) : (
                            <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#exemptionXLCollapse" role="button" aria-expanded="false" aria-controls="exemptionXLCollapse" />
                          )}
                        </div>
                      </div>
                    </button>
                    <div className="card-body collapse" id={"exemptionXLCollapse"} >
                      <div className="row">
                        <div className="col-12 col-xl-6">
                          {buildRow("Exemption Type",
                            <input className={"form-control"}
                              readOnly={true}
                              autoComplete={"off"}
                              type={"search"}
                              name={"Exemption Type"}
                              aria-label='Exemption Type'
                              value={this.state.selectedExemption?.ExemptionType ? getLabel(this.state.selectedExemption.ExemptionType, this.state.exemptionTypes).label : null}
                            />
                          )}
                          {buildRow("Immunizations for Exemption",
                            <Select
                              isSearchable={true}
                              isMulti={true}
                              placeholder={<div className="accessibilityText">Please Select...</div>}
                              noOptionsMessage={() => "No option"}
                              value={this.state.selectedExemption?.ExemptImmunizations ? this.state.selectedExemption?.ExemptImmunizations : null}
                              onChange={(e) => {
                                let indexOfLastElement = e?.length - 1
                                //@ts-ignore
                                if (e && e[indexOfLastElement]?.value === 0) {
                                  this.setState((prevState) => ({
                                    selectedExemption: {
                                      ...prevState.selectedExemption,
                                      ExemptImmunizations: this.state.immunizations
                                    }
                                  }))
                                } else {
                                  this.setState((prevState) => ({
                                    selectedExemption: {
                                      ...prevState.selectedExemption,
                                      ExemptImmunizations: e
                                    }
                                  }))
                                }
                                
                              }}
                              className={"state_select"}
                              aria-label={getReactSelectAriaLabelMultiselect("Immunizations for Exemption", this.state.selectedExemption?.ExemptImmunizations)}
                              options={this.state.immunizationOptions}
                              readOnly={!this.props.isAdmin}
                              isDisabled={!this.props.isAdmin}
                            />
                          )}
                          {this.state.selectedExemption?.ExemptionType === 5 ?
                            <>
                              {/* {buildRow("Contraindicated Immunizations",
                                <Select
                                  isSearchable={true}
                                  isMulti={true}
                                  placeholder={<div className="accessibilityText">Please Select...</div>}
                                  noOptionsMessage={() => "No option"}
                                  value={typeof this.state.selectedExemption?.ContraIndicatedImmunizations === "string" ? getLabelsForMultiSelect(this.state.selectedExemption?.ContraIndicatedImmunizations, this.state.immunizations) : this.state.selectedExemption?.ContraIndicatedImmunizations ? this.state.selectedExemption?.ContraIndicatedImmunizations : null}
                                  onChange={(e) => this.setState((prevState) => ({
                                    selectedExemption: {
                                      ...prevState.selectedExemption,
                                      ContraIndicatedImmunizations: e
                                    }
                                  }))}
                                  className={"state_select"}
                                  aria-label={getReactSelectAriaLabelMultiselect("Contraindicated Immunizations", this.state.selectedExemption?.ContraIndicatedImmunizations)}
                                  options={this.props.immunizations}
                                  readOnly={!this.props.isAdmin}
                                  isDisabled={!this.props.isAdmin}
                                />
                              )} */}
                              {buildRow("Child Harmful Health Condition",
                                <input
                                  className={"form-control"}
                                  maxLength={50}
                                  autoComplete={"off"}
                                  type={"search"}
                                  name={"Result"}
                                  aria-label='Child Harmful Health Condition'
                                  value={this.state.selectedExemption?.HarmfulCondition ? this.state.selectedExemption?.HarmfulCondition : ""}
                                  onChange={(e) => {
                                    this.setState((prevState) => ({
                                      selectedExemption: {
                                        ...prevState.selectedExemption,
                                        HarmfulCondition: e.target.value
                                      }
                                    }))
                                  }}
                                  readOnly={!this.props.isAdmin}
                                />
                              )}

                            </> : <></>
                          }
                        </div>
                        <div className="col-12 col-xl-6">
                          {this.state.selectedExemption?.ExemptionType === 2 ?
                            <>
                            </>
                            :
                            this.state.selectedExemption?.ExemptionType === 1 ?
                              <>
                                {buildRow("Reason",
                                  <div>
                                    <Select
                                      isSearchable={true}
                                      placeholder={<div className="accessibilityText">{"Please Select..."}</div>}
                                      noOptionsMessage={() => "No option"}
                                      value={this.state.selectedExemption.PersonalExemptionReason ? getLabel(this.state.selectedExemption.PersonalExemptionReason, this.state.exemptionReasons) : null}
                                      className={"state_select"}
                                      aria-label={`Reason for personal exemption ${this.state.selectedExemption?.PersonalExemptionReason ? `Option ${getLabel(this.state.selectedExemption?.PersonalExemptionReason, this.state.exemptionReasons).label} is selected.` : ""}`}
                                      onChange={(e) => {
                                        this.setState((prevState) => ({
                                          selectedExemption: {
                                            ...prevState.selectedExemption,
                                            PersonalExemptionReason: e.value
                                          }
                                        }))
                                      }}
                                      options={this.state.exemptionReasons}
                                    />
                                  </div>
                                )}
                                {this.state.selectedExemption.PersonalExemptionReason === 7 ? buildRow("Summary of Objection",
                                  <div>
                                    <textarea
                                      className={"form-control mr-3"}
                                      maxLength={600}
                                      autoComplete={"off"}
                                      name={"SummaryOfObjection"}
                                      value={this.state.selectedExemption?.ObjectionSummary}
                                      aria-label={"Summary of Objection"}
                                      onChange={(e) => {
                                        this.setState((prevState) => ({
                                          selectedExemption: {
                                            ...prevState.selectedExemption,
                                            ObjectionSummary: e.target.value
                                          }
                                        }))
                                      }}
                                    />
                                    <div className="row pt-1 pr-3 justify-content-end">
                                      <div className={this.state.selectedExemption?.ObjectionSummary && this.state.selectedExemption?.ObjectionSummary.length > 0 ? 'visible' : 'invisible'}>
                                        <div style={{ fontSize: '0.8em' }}>{this.state.selectedExemption?.ObjectionSummary && this.state.selectedExemption?.ObjectionSummary.length}/600</div>
                                      </div>
                                    </div>
                                  </div>
                                ) : <></>}
                              </>

                              :
                              <>

                              </>


                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*Physician */}
                  {this.state.selectedExemption?.ExemptionType === 5 && <div className="card">
                    <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#fileUploadXLCollapse" onClick={() => this.setState({ fileUploadArrowDown: !this.state.fileUploadArrowDown })} aria-expanded="false" aria-controls="fileUploadXLCollapse" >
                      <div className="row justify-content-between px-3">
                        <div className="">
                          <h4>{"Physician"}</h4>
                        </div>
                        <div className="col-2">
                          {this.state.fileUploadArrowDown ? (
                            <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#fileUploadXLCollapse" role="button" aria-expanded="false" aria-controls="fileUploadXLCollapse" />
                          ) : (
                            <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#fileUploadXLCollapse" role="button" aria-expanded="false" aria-controls="fileUploadXLCollapse" />
                          )}
                        </div>
                      </div>
                    </button>
                    <div className="card-body collapse" id={"fileUploadXLCollapse"} >
                      {(
                        <section className="row">
                          <section className="col-12 col-xl-6">
                            {buildRow("First Name",
                              <input
                                className={"form-control"}
                                maxLength={50}
                                autoComplete={"off"}
                                type={"search"}
                                name={"Result"}
                                aria-label='Physician First Name'
                                value={`${this.state.selectedExemption?.PhysicianFirstName ? this.state.selectedExemption?.PhysicianFirstName : ""}`}
                                onChange={(e) => {
                                  this.setState((prevState) => ({
                                    selectedExemption: {
                                      ...prevState.selectedExemption,
                                      PhysicianFirstName: e.target.value
                                    }
                                  }))
                                }}
                                readOnly={!this.props.isAdmin}
                              />, 'First Name', true
                            )}
                            {buildRow("Last Name",
                              <input
                                className={"form-control"}
                                maxLength={50}
                                autoComplete={"off"}
                                type={"search"}
                                name={"Result"}
                                aria-label='Physician Last Name'
                                value={`${this.state.selectedExemption?.PhysicianLastName ? this.state.selectedExemption?.PhysicianLastName : ""}`}
                                onChange={(e) => {
                                  this.setState((prevState) => ({
                                    selectedExemption: {
                                      ...prevState.selectedExemption,
                                      PhysicianLastName: e.target.value
                                    }
                                  }))
                                }}
                                readOnly={!this.props.isAdmin}
                              />, "Last Name", true
                            )}
                            <div key={"PhysicianAutoComplete"} className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'Address'}>
                              <label id={'Address'} htmlFor={'Physicianautocomplete'} className="col-12 col-sm-4 col-form-label px-3">{'Search Address'}</label>
                              <div className="col-12 col-sm-8 p-0 m-0 text-center text-md-left" id={'Address'}>
                                <AutoComplete key={"Physiciancomplete"} id={"Physicianautocomplete"}
                                  onChange={(addr) => { this.setState((prevState) => ({ selectedExemption: { ...prevState.selectedExemption, SearchAddress: addr } })) }}
                                  value={this.state.selectedExemption?.SearchAddress}
                                  updateFormData={(addr, city, state, zip, county, country) => {
                                    this.setState({ selectedExemption: { ...this.state.selectedExemption, PhysicianAddress: addr, PhysicianCity: city, PhysicianState: state, PhysicianZip: zip } })
                                  }}
                                />
                              </div>
                            </div>
                            {buildRow('Street Address',
                              <input
                                id={'StreetAddress'}
                                className={"form-control"}
                                maxLength={50}
                                autoComplete={"off"}
                                type={"search"}
                                name={'StreetAddress'}
                                aria-label={"Street Address"}
                                onChange={(e) => {
                                  this.setState((prevState) => ({
                                    selectedExemption: {
                                      ...prevState.selectedExemption,
                                      PhysicianAddress: e.target.value
                                    }
                                  }))
                                }}
                                value={this.state.selectedExemption?.PhysicianAddress}
                              />, 'Street Address', true
                            )}
                            {buildRow('City',
                              <input
                                id={'SecondaryAddressLine'}
                                className={"form-control"}
                                maxLength={50}
                                autoComplete={"off"}
                                type={"search"}
                                name={'City'}
                                aria-label={"City"}
                                onChange={(e) => {
                                  this.setState((prevState) => ({
                                    selectedExemption: {
                                      ...prevState.selectedExemption,
                                      PhysicianCity: e.target.value
                                    }
                                  }))
                                }}
                                value={this.state.selectedExemption?.PhysicianCity}
                              />, 'City', true
                            )}

                          </section>
                          <section className="col-12 col-xl-6">
                            {buildRow('State',
                              <Select
                                key={'State'}
                                id={'State'}
                                isSearchable={true}
                                placeholder={<div className="accessibilityText">{"Please Select..."}</div>}
                                noOptionsMessage={() => "No option"}
                                aria-label={"State"}
                                value={this.state.selectedExemption ? getLabel(this.state.selectedExemption.PhysicianState, this.props.states) : null}
                                onChange={(e) => {
                                  this.setState((prevState) => ({
                                    selectedExemption: {
                                      ...prevState.selectedExemption,
                                      PhysicianState: e.value
                                    }
                                  }))
                                }}
                                className={'state_select'}
                                options={this.props.states}
                              />, 'State'
                              , true
                            )}
                            {buildRow('Zipcode',
                              <input
                                id={'Zipcode'}
                                className={"form-control"}
                                maxLength={50}
                                autoComplete={"off"}
                                type={"search"}
                                name={'Zipcode'}
                                aria-label={"Zipcode"}
                                onChange={(e) => {
                                  this.setState((prevState) => ({
                                    selectedExemption: {
                                      ...prevState.selectedExemption,
                                      PhysicianZip: e.target.value
                                    }
                                  }))
                                }}
                                value={this.state.selectedExemption?.PhysicianZip}
                              />, 'Zipcode', true
                            )}
                            {buildRow('Apartment/Suite/Building #',
                              <input
                                id={'SecondaryAddressLine'}
                                className={"form-control"}
                                maxLength={50}
                                autoComplete={"off"}
                                type={"search"}
                                name={'SecondaryAddressLine'}
                                aria-label={"Apartment/Suite/Building #"}
                                onChange={(e) => {
                                  this.setState((prevState) => ({
                                    selectedExemption: {
                                      ...prevState.selectedExemption,
                                      PhysicianAdditionalAddressInfo: e.target.value
                                    }
                                  }))
                                }}
                                value={this.state.selectedExemption?.PhysicianAdditionalAddressInfo}
                              />, 'Apartment/Suite/Building #'
                            )}
                            {buildRow("Office Phone",
                              <PhoneInput
                                id={'PhysicianPhone'}
                                placeholder={"Enter phone number (10 digit)"}
                                defaultCountry="US"
                                onChange={(e) => {
                                  this.setState((prevState) => ({
                                    selectedExemption: {
                                      ...prevState.selectedExemption,
                                      PhysicianPhone: e
                                    }
                                  }))
                                }}
                                value={this.state.selectedExemption?.PhysicianPhone ? this.state.selectedExemption?.PhysicianPhone : ""}
                                readOnly={!this.props.isAdmin}
                                isDisabled={!this.props.isAdmin}
                              />, "Phone", true
                            )}
                            {buildRow("Email",
                              <input
                                className={"form-control"}
                                maxLength={50}
                                autoComplete={"off"}
                                type={"search"}
                                name={"Result"}
                                placeholder='name@example.com'
                                aria-label='Physician Email'
                                value={this.state.selectedExemption?.PhysicianEmail ? this.state.selectedExemption?.PhysicianEmail : ""}
                                onChange={(e) => {
                                  this.setState((prevState) => ({
                                    selectedExemption: {
                                      ...prevState.selectedExemption,
                                      PhysicianEmail: e.target.value
                                    }
                                  }))
                                }}
                              />, "Email", false
                            )}
                            {this.state.selectedExemption?.fileNames && this.state.selectedExemption?.fileNames.length > 0 && this.state.selectedExemption?.fileNames.map((fileName, index) => (
                              <div className="form-group row">
                                <label htmlFor={`SelectedFile${index}`} className="col-12 col-sm-4 col-form-label text-center text-md-left">{"Selected File"} #{index + 1}</label>
                                <div className="col-8 col-sm-6 p-0 pt-2 m-0 text-center text-md-left" id={`SelectedFile${index}`}>
                                  {fileName}
                                </div>
                                <button aria-label={`${"Selected File"} #${index + 1} Download`} className=" p-0 col-2 col-sm-1">
                                  <AiOutlineCloudDownload size={30} onClick={() => this.downloadFile(fileName)} />
                                </button>

                              </div>
                            ))}
                          </section>
                        </section>
                      )
                      }
                    </div>
                  </div>}
                  <div className="card">
                    <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#childXLCollapse" onClick={() => this.setState({ childArrowDown: !this.state.childArrowDown, })} aria-expanded="false" aria-controls="childXLCollapse">
                      <div className="row justify-content-between px-3">
                        <div className="">
                          <h4>Child</h4>
                        </div>
                        <div className="col-2">
                          {this.state.childArrowDown ? (
                            <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#childXLCollapse" role="button" aria-expanded="false" aria-controls="childXLCollapse" />
                          ) : (
                            <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#childXLCollapse" role="button" aria-expanded="false" aria-controls="childXLCollapse" />
                          )}
                        </div>
                      </div>
                    </button>
                    <div className="card-body collapse" id={"childXLCollapse"}>
                      <div className="row">
                        <div className="col-12 col-xl-6">
                          {buildRow("First Name",
                            <input className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={"Result"}
                              aria-label='Child First Name'
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ChildFirstName: e.target.value
                                  }
                                }))
                              }}
                              value={`${this.state.selectedExemption?.ChildFirstName}`}
                              readOnly={!this.props.isAdmin}
                            />, "Child First Name", true
                          )}
                          {buildRow("Last Name",
                            <input className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={"Result"}
                              aria-label='Child Last Name'
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ChildLastName: e.target.value
                                  }
                                }))
                              }}
                              value={`${this.state.selectedExemption?.ChildLastName}`}
                              readOnly={!this.props.isAdmin}
                            />, "Child Last Name", true
                          )}
                          {buildRow("Child's Mother's Maiden Name",
                            <input className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={"Result"}
                              aria-label="Child's Mother's Maiden Name"
                              value={this.state.selectedExemption?.MotherMaidenName}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    MotherMaidenName: e.target.value
                                  }
                                }))
                              }}
                              readOnly={!this.props.isAdmin}
                            />, "Child's Mother's Maiden Name", true
                          )}
                          {buildRow("DOB",
                            <input
                              id="ChildDateOfBirth"
                              min={'1000-01-01'}
                              max={new Date().toISOString().split('T')[0]}
                              className={"form-control"}
                              autoComplete={"off"}
                              type={"date"}
                              name={"DOB"}
                              aria-label="Child Date of Birth"
                              value={this.state.selectedExemption?.ChildDOB && this.state.selectedExemption?.ChildDOB ? new Date(this.state.selectedExemption.ChildDOB).toISOString().split('T')[0] : ''}
                              onChange={(e) => {
                                if (Date.parse(e.target.value)) {
                                  this.setState((prevState) => ({
                                    selectedExemption: {
                                      ...prevState.selectedExemption,
                                      ChildDOB: new Date(e.target.value)
                                    }
                                  }))
                                }
                              }}
                              readOnly={!this.props.isAdmin}
                            />, "Child DOB", true
                          )}
                          {buildRow("Birth State",
                            <Select
                              key={'ChildBirthState'}
                              id={'ChildBirthState'}
                              isSearchable={true}
                              placeholder={<div className="accessibilityText">Please Select...</div>}
                              noOptionsMessage={() => "No option"}
                              aria-label={getReactSelectAriaLabel("Child Birth State", getLabel(this.state.selectedExemption.ChildBirthState, this.props.states))}
                              value={this.state.selectedExemption ? getLabel(this.state.selectedExemption.ChildBirthState, this.props.states) : null}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ChildBirthState: e.value
                                  }
                                }))
                              }}
                              className={'state_select'}
                              options={this.props.states}
                              isDisabled={!this.props.isAdmin}
                              readOnly={!this.props.isAdmin}
                            />, "Child Birth State", true
                          )}
                          {buildRow("Birth Country",
                            <Select
                              key={'ChildBirthCountry'}
                              id={'ChildBirthCountry'}
                              isSearchable={true}
                              placeholder={<div className="accessibilityText">Please Select...</div>}
                              noOptionsMessage={() => "No option"}
                              aria-label={getReactSelectAriaLabel("Child Birth Country", getLabel(this.state.selectedExemption.ChildBirthCountry, this.props.countries))}
                              value={this.state.selectedExemption ? getLabel(this.state.selectedExemption.ChildBirthCountry, this.props.countries) : null}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ChildBirthCountry: e.value
                                  }
                                }))
                              }}
                              className={'state_select'}
                              options={countriesCopy}
                              isDisabled={!this.props.isAdmin}
                              readOnly={!this.props.isAdmin}
                            />, "Child Birth Country", true
                          )}
                        </div>
                        <div className="col-12 col-xl-6">
                          {buildRow("Gender",
                            <Select
                              key={'ChildGenderID'}
                              id={'ChildGenderID'}
                              isSearchable={true}
                              placeholder={<div className="accessibilityText">Please Select...</div>}
                              noOptionsMessage={() => "No option"}
                              aria-label={getReactSelectAriaLabel("Child Gender", getLabel(this.state.selectedExemption?.ChildGenderID, this.props.genders))}
                              value={this.state.selectedExemption ? getLabel(this.state.selectedExemption.ChildGenderID, this.props.genders) : null}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ChildGenderID: e.value
                                  }
                                }))
                              }}
                              className={'state_select'}
                              options={this.props.genders}
                              isDisabled={!this.props.isAdmin}
                              readOnly={!this.props.isAdmin}
                            />, "Child Gender", true
                          )}
                          {buildRow("Ethnicity",
                            <Select
                              key={'ChildEthnicityID'}
                              id={'ChildEthnicityID'}
                              isSearchable={true}
                              placeholder={<div className="accessibilityText">Please Select...</div>}
                              noOptionsMessage={() => "No option"}
                              aria-label={getReactSelectAriaLabel("Child Ethnicity", getLabel(this.state.selectedExemption?.ChildEthnicityID, this.props.ethnicities))}
                              value={this.state.selectedExemption ? getLabel(this.state.selectedExemption.ChildEthnicityID, this.props.ethnicities) : null}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ChildEthnicityID: e.value
                                  }
                                }))
                              }}
                              className={'state_select'}
                              options={this.props.ethnicities}
                              isDisabled={!this.props.isAdmin}
                              readOnly={!this.props.isAdmin}
                            />, "Child Ethnicity", true
                          )}
                          {buildRow("Race",
                            <Select
                              key={'ChildRaceID'}
                              id={'ChildRaceID'}
                              isSearchable={true}
                              isMulti={true}
                              placeholder={<div className="accessibilityText">Please Select...</div>}
                              isOptionDisabled={() => this.state.selectedExemption?.ChildRaceID?.length >= 3}
                              noOptionsMessage={() => "No option"}
                              aria-label={getReactSelectAriaLabelMultiselect("Child Race", this.state.selectedExemption?.ChildRaceID)}
                              value={this.state.selectedExemption?.ChildRaceID ? this.state.selectedExemption?.ChildRaceID : null}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ChildRaceID: e
                                  }
                                }))
                              }}
                              className={'state_select'}
                              options={this.props.races}
                              isDisabled={!this.props.isAdmin}
                              readOnly={!this.props.isAdmin}
                            />, "Child Race", true
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#parentXLCollapse" onClick={() => this.setState({ parentArrowDown: !this.state.parentArrowDown, })} aria-expanded="false" aria-controls="parentXLCollapse">
                      <div className="row justify-content-between px-3">
                        <div className="">
                          <h4>Parent/ Guardian</h4>
                        </div>
                        <div className="col-2">
                          {this.state.parentArrowDown ? (
                            <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#parentXLCollapse" role="button" aria-expanded="false" aria-controls="parentXLCollapse" />
                          ) : (
                            <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#parentXLCollapse" role="button" aria-expanded="false" aria-controls="parentXLCollapse" />
                          )}
                        </div>
                      </div>
                    </button>
                    <div className="card-body collapse" id={"parentXLCollapse"}>
                      <div className="row">
                        <div className="col-12 col-xl-6">
                          {buildRow("First Name",
                            <input className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={"Result"}
                              aria-label='Parent First Name'
                              value={`${this.state.selectedExemption?.ParentFirstName}`}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ParentFirstName: e.target.value
                                  }
                                }))
                              }}
                              readOnly={!this.props.isAdmin}
                            />, "Parent First Name", true
                          )}
                          {buildRow("Middle Initial",
                            <input className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={"Result"}
                              aria-label='Parent Middle Initial'
                              value={`${this.state.selectedExemption?.ParentMiddleInitial ? this.state.selectedExemption.ParentMiddleInitial : ""}`}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ParentMiddleInitial: e.target.value
                                  }
                                }))
                              }}
                              readOnly={!this.props.isAdmin}
                            />, "Parent Middle Initial"
                          )}
                          {buildRow("Last Name",
                            <input className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={"Result"}
                              aria-label='Parent Last Name'
                              value={`${this.state.selectedExemption?.ParentLastName}`}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ParentLastName: e.target.value
                                  }
                                }))
                              }}
                              readOnly={!this.props.isAdmin}
                            />, "Parent Last name", true
                          )}
                          {buildRow("Relationship",
                            <Select
                              isSearchable={true}
                              placeholder={<div className="accessibilityText">Please Select...</div>}
                              noOptionsMessage={() => "No option"}
                              value={this.state.selectedExemption ? getLabel(this.state.selectedExemption.RelationshipID, this.state.relationships) : null}
                              onChange={(e) => this.setState((prevState) => ({
                                selectedExemption: {
                                  ...prevState.selectedExemption,
                                  RelationshipID: e.value
                                }
                              }))}
                              className={"state_select"}
                              aria-label={getReactSelectAriaLabel("Relationship to Child", getLabel(this.state.selectedExemption.RelationshipID, this.state.relationships))}
                              options={this.state.relationships}
                              isDisabled={!this.props.isAdmin}
                              readOnly={!this.props.isAdmin}
                            />, "Relationship", true
                          )}

                          <div key={"ParentAutoComplete"} className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'Address'}>
                            <label id={'Address'} htmlFor={'Parentautocomplete'} className="col-12 col-sm-4 col-form-label px-3">{'Search Address'}</label>
                            <div className="col-12 col-sm-8 p-0 m-0 text-center text-md-left" id={'Address'}>
                              <AutoComplete key={"Parentautocomplete"} id={"Parentautocomplete"}
                                onChange={(addr) => { this.setState((prevState) => ({ selectedExemption: { ...prevState.selectedExemption, SearchAddress: addr } })) }}
                                value={this.state.selectedExemption?.SearchAddress}
                                updateFormData={(addr, city, state, zip, county, country) => {
                                  this.setState({ selectedExemption: { ...this.state.selectedExemption, ParentAddress: addr, ParentCity: city, ParentState: state, ParentZip: zip, ParentCounty: county } })
                                }}
                              />
                            </div>
                          </div>
                          {buildRow('Street Address',
                            <input
                              id={'StreetAddress'}
                              className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={'StreetAddress'}
                              aria-label={"Street Address"}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ParentAddress: e.target.value
                                  }
                                }))
                              }}
                              value={this.state.selectedExemption?.ParentAddress}
                            />, 'Street Address', true
                          )}
                          {buildRow('City',
                            <input
                              id={'SecondaryAddressLine'}
                              className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={'City'}
                              aria-label={"City"}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ParentCity: e.target.value
                                  }
                                }))
                              }}
                              value={this.state.selectedExemption?.ParentCity}
                            />, 'City', true
                          )}

                        </div>
                        <div className="col-12 col-xl-6">
                          {buildRow('State',
                            <Select
                              key={'State'}
                              id={'State'}
                              isSearchable={true}
                              placeholder={<div className="accessibilityText">{"Please Select..."}</div>}
                              noOptionsMessage={() => "No option"}
                              aria-label={"State"}
                              value={this.state.selectedExemption ? getLabel(this.state.selectedExemption.ParentState, this.props.states) : null}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ParentState: e.value
                                  }
                                }))
                              }}
                              className={'state_select'}
                              options={this.props.states}
                            />, 'State'
                            , true
                          )}
                          {buildRow('Zipcode',
                            <input
                              id={'Zipcode'}
                              className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={'Zipcode'}
                              aria-label={"Zipcode"}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ParentZip: e.target.value
                                  }
                                }))
                              }}
                              value={this.state.selectedExemption?.ParentZip}
                            />, 'Zipcode', true
                          )}
                          {buildRow('County',
                            <input
                              id={'County'}
                              className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={'County'}
                              aria-label={"County"}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ParentCounty: e.target.value
                                  }
                                }))
                              }}
                              value={this.state.selectedExemption?.ParentCounty}
                            />, 'County', true
                          )}
                          {buildRow('Apartment/Suite/Building #',
                            <input
                              id={'SecondaryAddressLine'}
                              className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={'SecondaryAddressLine'}
                              aria-label={"Apartment/Suite/Building #"}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ParentAdditionalAddressInfo: e.target.value
                                  }
                                }))
                              }}
                              value={this.state.selectedExemption?.ParentAdditionalAddressInfo}
                            />, 'Apartment/Suite/Building #'
                          )}
                          {buildRow("Phone",
                            <PhoneInput
                              id={'ParentPhone'}
                              placeholder={"Enter phone number (10 digit)"}
                              defaultCountry="US"
                              aria-label='Parent Phone'
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ParentPhone: e
                                  }
                                }))
                              }}
                              value={this.state.selectedExemption?.ParentPhone}
                              readOnly={!this.props.isAdmin}
                            />, "Parent Phone", true
                          )}
                          {buildRow("Email",
                            <input className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={"Result"}
                              placeholder='name@example.com'
                              aria-label='Parent Email'
                              value={this.state.selectedExemption?.ParentEmail}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  selectedExemption: {
                                    ...prevState.selectedExemption,
                                    ParentEmail: e.target.value
                                  }
                                }))
                              }}
                              readOnly={!this.props.isAdmin}
                            />, "Parent Email", true
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-2">
                    <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#schoolXLCollapse" onClick={() => this.setState({ schoolArrowDown: !this.state.schoolArrowDown, })} aria-expanded="false" aria-controls="schoolXLCollapse">
                      <div className="row justify-content-between px-3">
                        <div className="">
                          <h4>School/ Child Care</h4>
                        </div>
                        <div className="col-2">
                          {this.state.schoolArrowDown ? (
                            <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#schoolXLCollapse" role="button" aria-expanded="false" aria-controls="schoolXLCollapse" />
                          ) : (
                            <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#schoolXLCollapse" role="button" aria-expanded="false" aria-controls="schoolXLCollapse" />
                          )}
                        </div>
                      </div>
                    </button>
                    <div className="card-body collapse" id={"schoolXLCollapse"}>
                      <div className="row">
                        <div className="col-12">
                          {this.state.selectedExemption?.EducationSite?.map((site, index) =>
                            <section key={`site${index + 1}`} className='border rounded py-2 px-4 mb-3' style={{ borderColor: "rgba(0, 0, 0, 0.125)" }}>
                              {this.state.selectedExemption.EducationSite.length > 1 ?
                                <div className="row justify-content-end">
                                  <div className="d-flex justify-content-center align-content-center">
                                    <button style={{ outline: 'none' }} type="button" className="close"
                                      aria-label="Remove School/ Child Care" onClick={() => this.handleRemoveSchool(index)}>
                                      <span aria-hidden="true" className='d-inline-block pb-3 mr-3' style={{ fontSize: '1.5em', fontWeight: 'bold', color: "red" }}>&times;</span>
                                    </button>
                                  </div>
                                </div> : <></>}
                              {buildRow('School/ Child Care',
                                <Select
                                  key={'School/ChildCare'}
                                  id={'School/ChildCare'}
                                  isClearable={true}
                                  isSearchable={true}
                                  placeholder={"Please Select..."}
                                  noOptionsMessage={() => "No option"}
                                  aria-label={getReactSelectAriaLabel("School/ Child Care", getLabel(this.state.selectedExemption.EducationSite[index].schoolOrChildCare, [{ label: "School", value: 0 }, { label: "Child Care", value: 1 }]))}
                                  value={getLabel(this.state.selectedExemption.EducationSite[index].schoolOrChildCare, [{ label: "School", value: 0 }, { label: "Child Care", value: 1 }])}
                                  onChange={(e) => {
                                    let schoolArray = this.state.selectedExemption.EducationSite
                                    schoolArray[index] = { schoolOrChildCare: e?.value, SchoolDistrict: null, School: null, ChildCareSite: null, SchoolGrade: null, SchoolPhone: null }
                                    this.setState((prevState) => ({
                                      selectedExemption: {
                                        ...this.state.selectedExemption,
                                        EducationSite: schoolArray,
                                      }
                                    }))
                                  }}
                                  className={'state_select'}
                                  options={[{ label: "School", value: 0 }, { label: "Child Care", value: 1 }]}
                                />, 'School/ Child Care', true
                              )}
                              {this.state.selectedExemption.EducationSite[index].schoolOrChildCare === 0 ? <>

                                {buildRow('Name of School',
                                  <Select
                                    key={'SchoolName'}
                                    id={'SchoolName'}
                                    isSearchable={true}
                                    isClearable={true}
                                    placeholder={"Please Select..."}
                                    filterOption={createFilter({ ignoreAccents: false })}
                                    noOptionsMessage={() => "No option"}
                                    aria-label={getReactSelectAriaLabel("School Name", getLabel(this.state.selectedExemption.EducationSite[index].School, this.state.schoolOptions))}
                                    value={this.state.selectedExemption.EducationSite[index].School ? getLabel(this.state.selectedExemption.EducationSite[index].School, this.state.schools.map((s) => {
                                      return { label: s.SchoolName, value: s.ID }
                                    })) : null}
                                    onChange={(e) => {
                                      this.handleUpdateSchool(index, "School", e?.value)
                                    }}
                                    className={'state_select'}
                                    options={this.state.selectedExemption.EducationSite[index].SchoolDistrict ? this.state.schools.filter((s) => {
                                      return s.SchoolDistrictID === this.state.selectedExemption.EducationSite[index].SchoolDistrict
                                    }).map((s) => {
                                      return { label: s.SchoolName, value: s.ID }
                                    }) : this.state.schools.map((s) => {
                                      return { label: s.SchoolName, value: s.ID }
                                    })}
                                    isDisabled={!this.props.isAdmin}
                                    readOnly={!this.props.isAdmin}
                                  />, 'Name Of School', true
                                )}
                                {this.state.selectedExemption.EducationSite[index]?.School === -1 ? buildRow('Other Name of School',
                                  <input
                                    id={'SchoolDistrict'}
                                    className={"form-control"}
                                    maxLength={50}
                                    autoComplete={"off"}
                                    type={"search"}
                                    name={'SchoolDistrict'}
                                    aria-label={"School"}
                                    onChange={(e) => {
                                      this.handleUpdateSchool(index, "OtherSchool", e?.target?.value)
                                    }}
                                    value={this.state.selectedExemption.EducationSite[index]?.OtherSchool}
                                  />, 'School District', true
                                ) : <></>}
                                {buildRow('School District',
                                  <Select
                                    key={'SchoolGrade'}
                                    id={'SchoolGrade'}
                                    isSearchable={true}
                                    placeholder={"Please Select..."}
                                    isClearable={true}
                                    noOptionsMessage={() => "No option"}
                                    filterOption={createFilter({ ignoreAccents: false })}
                                    aria-label={getReactSelectAriaLabel("School District", getLabel(this.state.selectedExemption.EducationSite[index]?.SchoolDistrict, this.state.districts))}
                                    value={this.state.selectedExemption.EducationSite[index]?.SchoolDistrict ? getLabel(this.state.selectedExemption.EducationSite[index]?.SchoolDistrict, this.state.districts) : null}
                                    onChange={(e) => {
                                      this.handleUpdateSchool(index, "SchoolDistrict", e?.value ? e.value : null)
                                    }}
                                    className={'state_select'}
                                    options={this.state.districts}
                                    isDisabled={!this.props.isAdmin}
                                    readOnly={!this.props.isAdmin}
                                  />, 'School District', true
                                )}
                                {this.state.selectedExemption.EducationSite[index]?.SchoolDistrict === -1 ? buildRow('Other School District',
                                  <input
                                    id={'SchoolDistrict'}
                                    className={"form-control"}
                                    maxLength={50}
                                    autoComplete={"off"}
                                    type={"search"}
                                    name={'SchoolDistrict'}
                                    aria-label={"School District"}
                                    onChange={(e) => {
                                      this.handleUpdateSchool(index, "OtherSchoolDistrict", e?.target?.value)
                                    }}
                                    value={this.state.selectedExemption.EducationSite[index]?.OtherSchoolDistrict}
                                  />, 'School District', true
                                ) : <></>}
                              </> : this.state.selectedExemption.EducationSite[index].schoolOrChildCare === 1 ?
                                buildRow('Name of Child Care',
                                  <Select
                                    key={'ChildCareSite'}
                                    id={'ChildCareSite'}
                                    isSearchable={true}
                                    isClearable={true}
                                    placeholder={"Please Select..."}
                                    noOptionsMessage={() => "No option"}
                                    aria-label={getReactSelectAriaLabel("Child Care Name", getLabel(this.state.selectedExemption.EducationSite[index].ChildCareSite, this.state.childCareFacilities))}
                                    value={this.state.selectedExemption.EducationSite[index].ChildCareSite ? getLabel(this.state.selectedExemption.EducationSite[index].ChildCareSite, this.state.childCareFacilities) : null}
                                    onChange={(e) => {
                                      this.handleUpdateSchool(index, "ChildCareSite", e?.value)
                                    }}
                                    className={'state_select'}
                                    options={this.state.childCareFacilities}
                                  />, 'Name Of Child Care', true
                                ) : <></>
                              }
                              {this.state.selectedExemption.EducationSite[index].ChildCareSite === -1 ?
                                buildRow('Other Name of Child Care',
                                  <input
                                    id={'ChildCareSite'}
                                    className={"form-control"}
                                    maxLength={50}
                                    autoComplete={"off"}
                                    type={"search"}
                                    name={'ChildCareSite'}
                                    aria-label={"Child Care"}
                                    onChange={(e) => {
                                      this.handleUpdateSchool(index, "OtherChildCareSite", e?.target?.value)
                                    }}
                                    value={this.state.selectedExemption.EducationSite[index]?.OtherChildCareSite}
                                  />, 'Name Of Child Care', true
                                ) : <></>}
                              {this.state.selectedExemption.EducationSite[index].schoolOrChildCare === 0 && buildRow('Grade',
                                <Select
                                  key={'SchoolGrade'}
                                  id={'SchoolGrade'}
                                  isSearchable={true}
                                  isClearable={true}
                                  placeholder={"Please Select..."}
                                  noOptionsMessage={() => "No option"}
                                  aria-label={getReactSelectAriaLabel("School Grade", getLabel(this.state.selectedExemption.EducationSite[index].SchoolGrade, this.state.gradeOptions))}
                                  value={this.state.selectedExemption.EducationSite[index].SchoolGrade ? getLabel(this.state.selectedExemption.EducationSite[index].SchoolGrade, this.state.gradeOptions) : null}
                                  onChange={(e) => {
                                    this.handleUpdateSchool(index, "SchoolGrade", e?.value)
                                  }}
                                  className={'state_select'}
                                  options={this.state.gradeOptions}
                                />, 'School Grade of child', true
                              )}
                              {buildRow('School Phone',
                                <PhoneInput
                                  id={'SchoolPhone'}
                                  placeholder={"Enter phone number (10 digit)"}
                                  onChange={(e) =>
                                    this.handleUpdateSchool(index, "SchoolPhone", e)
                                  }
                                  defaultCountry="US"
                                  aria-label="School Phone Number"
                                  value={this.state.selectedExemption.EducationSite[index].SchoolPhone} />, "School Phone"
                              )}</section>)}
                        </div>
                      </div>
                    </div>
                  </div>




                </div>
                <div className="card-footer mb-2 mr-2 ml-0 d-flex d-md-block flex-column justify-content-center align-items-center" style={{ backgroundColor: "transparent", borderTop: "none" }} >
                  {/* <button type="button" className={"btn btn-success mr-md-3 my-2"} disabled={this.state.selectedExemption?.isApproved} onClick={() => this.changeStatus(1)}> Approve Exemption </button>
                    <button type="button" className={"btn btn-danger mr-md-3 my-2"} disabled={this.state.selectedExemption?.isDenied} onClick={() => this.changeStatus(0)}> Deny Exemption </button> */}
                  {this.props.isAdmin && <button className={"btn btn-success my-2"} style={{ float: "right" }} onClick={() => this.submitForm()} > Update Exemption </button>}
                  {/* {this.state.selectedExemption.ExemptionType === 5 && <button className={"btn btn-primary my-2"} style={{ float: "left" }} onClick={() => {
                    sweetalert.fire({
                      icon: "question",
                      title: "",
                      showConfirmButton: true,
                      confirmButtonText: "Yes",
                      showDenyButton: true,
                      denyButtonText: "No",
                      text: "Are you sure you want to resend the physician email?",
                    }).then(async (res) => {
                      if (res.isConfirmed) {
                        this.handleSendPhysicianEmail()
                      }
                    })
                  }} > Resend Physician Email </button>} */}
                  {!this.props.isAdmin && <button className={"btn btn-primary my-2 "} style={{ float: "right" }} onClick={() => hideModal(ExemptionRecordModal.ID)}> Close </button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
